import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/UserStrategyManager.vue'; // Import the HomePage component
import ProfilePage from '../views/UserProfile.vue'; // Import the ProfilePage component


const routes = [
  { path: '/', component: HomePage }, // Home page route
  { path: '/profile', component: ProfilePage }, // Profile page route
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use HTML5 history mode
  routes, // Pass the routes array to the router instance
});

export default router; // Export the router instance
