import { createAuth0 } from '@auth0/auth0-vue';


const Auth0Plugin = createAuth0({
  domain: "dev-ple2jaku.us.auth0.com",
  clientId: "f0K8IxmyNhY0Npx4oPeXSqG6fdrOwX6E",
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
});




export {Auth0Plugin}
