<!-- src/components/UserProfile.vue -->
<template>
    <div v-if="isAuthenticated">
      <p>Welcome, {{ user.name }}!</p>
      <img :src="user.picture" alt="Profile" />
    </div>
  </template>
  
  <script>
  import { useAuth0 } from '@auth0/auth0-vue';
  
  export default {
    setup() {
      const { user, isAuthenticated } = useAuth0();
  
      return {
        user,
        isAuthenticated,
      };
    },
  };
  </script>
  