<template>
  <v-app>
    <v-container>
      <v-theme-provider class="pa-10" theme="light">
        <!-- v-app-bar for navigation and controls -->
        <v-app-bar :elevation="2" density="compact" theme="dark">
          <template v-slot:prepend>
            <!-- Replace v-app-bar-nav-icon with v-btn and mdi-menu icon -->         
            
              <img :src="logo" alt="Logo" style="width: 80px; height: 80px;" />
            
            <v-app-bar-title  class="ml-auto" >Trend Rooster</v-app-bar-title>
          </template>
 
          

          <template v-slot:append >
           
            <!-- Bell icon for alerts -->
            <v-btn icon  value="alertlog" @click="alert_log = !alert_log">
              <v-icon>mdi-bell</v-icon>
            </v-btn>


            <div class="d-flex justify-space-around">
    <v-btn icon id="menu-activator">

      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-menu activator="#menu-activator">
      <v-list>
        <v-list-item
          prepend-icon="mdi-account"
          title="Profile"
          value="profile"
        />
        <v-list-item
          prepend-icon="mdi-receipt-text"
          title="Billing"
          value="billing"
        />
        <v-list-item
          prepend-icon="mdi-cash-sync"
          title="Subscription"
          value="subscription"
        />
      </v-list>
    </v-menu>
  </div>
            
            <v-btn v-if="isAuthenticated" icon @click="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
            <v-btn v-else @click="login" color="primary"  elevation="4"  class="ma-2" outlined rounded >Log In</v-btn>
          </template>
        </v-app-bar>
        <div>
          
        </div>
    <!-- Navigation Drawer -->
    <v-navigation-drawer       
        :rail="rail"
        permanent
        @click="rail "  v-if="isAuthenticated"  density="compact" nav theme="dark" 
      >
          <v-list density="compact" nav >
            <v-list-item density="compact" prepend-icon="mdi-cog" title="Create Strategy" value="create"  @click="selectedView = 'CreateStrategy'"/>
            <v-divider></v-divider>
            <v-list-item prepend-icon="mdi-rocket-launch" title="Deployed Strategy" value="deployed"  @click="selectedView = 'ActiveStrategies'"/>
          <v-list-item prepend-icon="mdi-strategy" title="Evaluate Strategy" value="alertlog" @click="alert_log = !alert_log"/>
        </v-list>
        <div class="d-flex justify-end">
          <v-btn icon variant="text" @click="rail = !rail" >
          <v-icon>{{ !rail ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </div>
          
      </v-navigation-drawer>
   
        <v-main>
          <v-container>

          <div v-if="isAuthenticated">            
            
            <component :is="selectedView" :userId="userId" ></component>
            
            <!-- Right Navigation Drawer for Alerts -->
            <v-navigation-drawer location="right"   v-model="alert_log" app permanent density="compact" nav >
                  <AlertsWidget :userId="userId" />

                  
          
                <!-- Toggle for right drawer -->
                <div class="d-flex justify-end">
                  <v-btn icon variant="text" @click="alert_log = !alert_log">
                    <v-icon>{{ alert_log ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
                  </v-btn>
                </div>
              </v-navigation-drawer>
            

          </div>
          <div v-else>
            <p> Log in </p>
            
          </div>
        </v-container>
        </v-main>
      </v-theme-provider>
    </v-container>
  </v-app>
</template>


<script>


import { useAuth0 } from '@auth0/auth0-vue';
import logo from '@/assets/logo.png'; // Import logo
import ActiveStrategies from './components/ActiveStrategies.vue'; 
import CreateStrategy from './components/CreateStrategy.vue'; 
import AlertsWidget from './components/AlertsWidget.vue';

export default {
  name: 'App',
  components: {
    ActiveStrategies,
    AlertsWidget,
    CreateStrategy,
  },
  methods: {
    async fetchUserProfile() {
      // Fetch the user profile after authentication
      try {
        const response = await this.$auth.getUser(); // Adjust based on your auth library
        this.userProfile = response.data; // Assuming the picture is in response.data.picture
        this.isAuthenticated = true; // Set authentication state
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    }
  },  



  data() {
    return {
      logo, // Store the imported logo
      drawer: false, // Add the drawer property        
      rail: true,
      selectedView: ActiveStrategies,
      alert_log: false,
      userProfile: {} // Object to hold user profile info

    };
  },
  setup() {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

    return {
      isAuthenticated,
      login: loginWithRedirect,
      logout: () => logout({ returnTo: window.location.origin }),
      user,
    };
  },
  computed: {
    userId() {
      return this.user ? this.user.sub : null;
    },
    username() {
      return this.user ? this.user.name : '';
    },

  },
};
</script>

<style scoped>
/* Basic styling for the layout */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

main {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

p {
  font-size: 1.2rem;
  text-align: center;
}
</style>
