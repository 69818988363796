<template>
  <v-card>
    <v-card-title>Active Strategies</v-card-title>
    <v-text-field
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      variant="outlined"
      hide-details
      single-line
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="strategies"
      :search="search"
      dense
      class="elevation-1"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.strategy_id" class="custom-row">
            <td class="custom-cell">{{ item.strategy_name }}</td>
            <td class="custom-cell">{{ item.instrument }}</td>
            <td class="custom-cell">{{ item.subscription_date }}</td>
            <td class="custom-cell">
              <v-btn
                v-if="item.platform_url === 'NA'"
                color="green"
                small
                @click="openDeployDialog(item)"
              >
                Deploy
              </v-btn>
              <v-btn
                v-else
                color="blue"
                small
                @click="openDeployDialog(item)" 
              >
                Live <v-icon
          icon="mdi-wrench"
          end
        ></v-icon>
              </v-btn>
            </td>
            <td class="custom-cell">
              <v-btn icon color="black" small @click="startBacktest(item)">
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </td>
            <td class="custom-cell">
              <v-btn icon color="black" small @click="unsubscribe(item.strategy_id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- Deploy/Edit Config Dialog -->
    <v-dialog v-model="deployDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="selectedStrategy?.platform_url === 'NA'">Deploy Strategy</span>
          <span v-else>Edit Config</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="deployToken"
            label="Token"
            required
          ></v-text-field>
          <v-text-field
            v-model="deployPlatformUrl"
            label="Platform Hook URL"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" text @click="deployConfig">Submit</v-btn>
          <v-btn color="red" text @click="closeDeployDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


---

### Script Section

```javascript
<script>
import axios from "../axios";

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      search: "",
      strategies: [], // List of strategies
      deployDialog: false, // State for dialog visibility
      selectedStrategy: null, // Holds the selected strategy for deployment
      deployToken: "",
      deployPlatformUrl: "",
      headers: [
        { text: "Strategy Name", value: "strategy_name" },
        {text: "Instrument", value: "instrument" },
        { text: "Enabled Date", value: "subscription_date" },
        { text: "Actions", value: "action" },
        { text: "Backtest", value: "backtest" },
        { text: "Delete", value: "delete" }, // Added Delete column
      ],
    };
  },

  methods: {
    async loadUserStrategies() {
      try {
        if (this.userId) {
          const response = await axios.get(`/usersubscriptions/${this.userId}`);
          this.strategies = response.data.map((strategy) => ({
            ...strategy,
          }));
        }
      } catch (error) {
        console.error("Error loading user strategies:", error);
      }
    },

    async unsubscribe(strategyId) {
      if (confirm("Are you sure you want to unsubscribe from this strategy?")) {
        try {
          await axios.delete("/remove_user_subscription", {
            data: {
              user_id: this.userId,
              strategy_id: strategyId,
            },
          });
          alert("Unsubscribed successfully!");
          this.loadUserStrategies();
        } catch (error) {
          console.error("Error unsubscribing from strategy:", error);
        }
      }
    },

    openDeployDialog(strategy) {
      this.selectedStrategy = strategy;
      this.deployToken = strategy.token || "";
      this.deployPlatformUrl = strategy.platform_url || "";
      this.deployDialog = true;
    },

    closeDeployDialog() {
      this.deployDialog = false;
      this.selectedStrategy = null;
      this.deployToken = "";
      this.deployPlatformUrl = "";
    },

    async deployConfig() {
      if (!this.deployToken || !this.deployPlatformUrl) {
        alert("Please provide both Token and Platform Hook URL.");
        return;
      }

      try {
        await axios.put("/update_user_subscriptionInfo", {
          user_id: this.userId,
          token: this.deployToken,
          strategy_id: this.selectedStrategy.strategy_id,
          platform_url: this.deployPlatformUrl,
        });

        alert("Configuration updated successfully!");
        this.closeDeployDialog();
        this.loadUserStrategies();
      } catch (error) {
        console.error("Error deploying configuration:", error);
      }
    },

    startBacktest(strategy) {
      alert(`Starting backtest for strategy: ${strategy.strategy_name}`);
      // Add your backtest logic here
    },
  },

  created() {
    this.loadUserStrategies();
  },
  mounted(){
    this.loadUserStrategies();
  },
};
</script>


<style scoped>
/* Add spacing and padding for better readability */
.custom-row {
  height: 56px; /* Adjust row height */
}

.custom-cell {
  padding: 12px 16px; /* Add padding to cells */
}

/* Add spacing between buttons in the Actions column */
.custom-cell v-btn {
  margin-right: 8px; /* Add spacing between buttons */
}

/* Adjust header styles if needed */
.v-data-table-header th {
  background-color: #f5f5f5; /* Light background color */
  color: #333; /* Text color */
  padding: 16px; /* Add padding */
}

.custom-cell v-btn {
  margin-right: 8px; /* Space between icons if needed */
}
</style>
