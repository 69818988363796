<template>  
  <div class="container">
    <div class="layout">
      <!-- Sidebar Menu -->
      <aside class="sidebar">
        <ul>
          <li @click="selectSection('activeStrategies')" :class="{ active: currentSection === 'activeStrategies' }">
            <span class="icon">📊</span> My Portfolio Systems
          </li>
          <ul>
          <li v-for="portfolio in portfolios" :key="portfolio"  @click="selectPortfolio(portfolio)" :class="{ active: selectedPortfolio === portfolio }">
            - {{ portfolio }}
          
          </li>
          
          </ul>          
          <hr/>
          <li @click="selectSection('strategyTrades')" :class="{ active: currentSection === 'strategyTrades' }">
            <span class="icon">📉</span>  Trade Signal Log
          </li>
        </ul>
      </aside>

      <!-- Main Content Area -->
      <main class="main-content">
        <component :is="currentSectionComponent" :userId="userId" :portfolio="selectedPortfolio"></component>
        <!-- Right-Side Alert Widget 
        <AlertsWidget :userId="userId" /-->
      </main>


    </div>
  </div>
</template>

<script>

import AlertsWidget from './AlertsWidget.vue';
import ActiveStrategies from './ActiveStrategies.vue'; // New component
import StrategyTrades from './StrategyTrades.vue'; // New component
import axios from '../axios'; // Importing axios


export default {
  components: {
    ActiveStrategies,
    StrategyTrades,
    AlertsWidget, // Register AlertsWidget here
  },
  props: {
      userId: {
        type: String,
        required: true,
      },
    },
  data() {
      return {      
      portfolios: [],
      currentSection: 'activeStrategies', // Default section
      selectedPortfolio: null,
    };
  },
  computed: {
    currentSectionComponent() {
      // Dynamically switch between components based on selected section
      return {
        activeStrategies: ActiveStrategies,        
        strategyTrades: StrategyTrades,
      }[this.currentSection];
    },
  },


  async created() {
    this.portfolios = await this.fetchPortfolios();
  },
  methods: {
    checkUserId() {
      console.log('User ID entered:', this.userId);
      // Trigger reloading of strategies or other user-specific data here if needed
    },
    selectSection(section) {
      this.currentSection = section;
      // You can load section-specific data here if necessary
    },
    selectPortfolio(portfolio) {
      this.selectedPortfolio = portfolio;
      console.log('Selected portfolio:', portfolio);
      // You can pass this portfolio to another component or perform other actions here
    },


    async fetchPortfolios(){
      try {
        const response = await axios.get('/portfolios');
        return response.data;
      } catch (error) {
        console.error('Error fetching portfolios:', error);
        return [];
      }
   },   
  },

};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  display: flex;
  justify-content: space-between; /* Adjusts spacing between title and user ID */
  align-items: center; /* Vertically centers items */
  padding: 15px;
  background-color: #007bff;
  color: white;
}

header h1 {
  margin: 0; /* Removes default margin */
}

.user-id-container {
  display: flex; /* Aligns input and button horizontally */
}

header input {
  padding: 5px;
  margin-right: 10px;
}

header button {
  padding: 5px 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
}

header button:hover {
  background-color: #004080;
}

.layout {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.3s;
  display: flex;
  align-items: center;
}

.sidebar li:hover {
  background-color: #e0e0e0;
}

.sidebar li.active {
  background-color: #d0d0d0; /* Highlight active section */
}

.icon {
  margin-right: 10px;
}

.main-content {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
}
</style>
