import { createApp } from 'vue';
import App from './App.vue';    
import { Auth0Plugin } from './auth/authservice'; // Import the Auth0Plugin
import router from './router';

// Import Vuetify 3 plugins and styles
import 'vuetify/styles'; // Vuetify's base styles
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Import MDI icons
//import { VApp, VAppBar, VBtn, VIcon, VContainer, VThemeProvider, VNavigationDrawer, VList, VListItem, VListItemIcon } from 'vuetify/components';
import * as vuecomp from 'vuetify/components'
import * as vuedirectives from 'vuetify/directives'



// Create a Vuetify instance
const vuetify = createVuetify({
    components:vuecomp,
    vuedirectives,
    icons: {
      defaultSet: 'mdi', // Set default icon font to Material Design Icons
    },
    theme: {
      defaultTheme: 'light', // Enable dark theme globally
    },
  });

const app = createApp(App);


// Use Auth0 plugin with the required parameters
app.use(Auth0Plugin);
app.use(router);
app.use(vuetify); // Add Vuetify to the app

app.mount('#app');
