<template>
  <!-- Toolbar with Refresh and Search Buttons -->
    <v-toolbar flat dense class="align-center mb-3" density="compact"  theme="light"  >
      <v-list>
      <v-list-item title="Strategy Alert Log" value="log">                        
      </v-list-item><v-divider></v-divider>
      </v-list>
      <!-- Refresh Button -->      
      <v-btn icon @click="refreshAlerts" small density="compact">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <!-- Search Button -->
      <v-btn icon @click="toggleSearch" small density="compact">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- Search Textbox (appears below toolbar when searchOpen is true) -->
    <v-text-field  density="compact" 
      v-if="searchOpen"
      v-model="searchQuery"
      label="Search"
      dense
      outlined
      clearable
      class="mb-3" small
    ></v-text-field >

    <!-- Alerts List Grouped by Date -->
    <v-list density="compact" >
      <template v-for="(alerts, date) in groupedAlerts" :key="date">
        <!-- Display Date as Month Day -->
        <v-list-item>
          <v-list-item-title class="font-weight-bold" > {{ formatMonthDay(date) }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <!-- Display each alert under the date -->
        <v-list-item v-for="alert in alerts" :key="alert.alert_id">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon class="mr-2" :color="alert.alert_type === 'entry' ? 'green' : 'red'">
                {{ alert.alert_type === 'entry' ? 'mdi-triangle' : 'mdi-triangle-down' }}
              </v-icon>
              {{ alert.strategy_id.replace(/_/g, ' ') }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Price @ {{ alert.price }} | {{ formatTime(alert.dispatch_timestamp) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  
</template>

<script>
import axios from '../alert-axios'; // Importing axios

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alerts: [],
      searchQuery: '',
      searchOpen: false,
    };
  },
  async created() {
    await this.refreshAlerts();
  },
  watch: {
      userId(newUserId) {
        console.log('User ID changed:', this.userId, newUserId);
        if (newUserId) {
          this.refreshAlerts();
        }
      },},


  computed: {
    groupedAlerts() {
      
      const sortedAlerts = [...this.filteredAlerts].sort((a, b) => b.dispatch_timestamp - a.dispatch_timestamp);
      const grouped = {};
      sortedAlerts.forEach((alert) => {
        const date = this.formatDate(alert.dispatch_timestamp);
        
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(alert);
      });
      return grouped;
    },

    filteredAlerts() {
      if (this.searchQuery) {
        return this.alerts.filter((alert) =>
          alert.strategy_id
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      }      
      return this.alerts;
    },
  },
  methods: {
  async refreshAlerts() {
    try {
      if(this.userId){
       const response = await axios.get(`/alerts/user/${this.userId}`);
        this.alerts = response.data;
      }
      else{
        return [];
      }

    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  },
  toggleSearch() {
    this.searchOpen = !this.searchOpen;
    if (!this.searchOpen) {
      this.searchQuery = '';
    }
  },

  formatDate(timestamp) {
    const time = parseInt(timestamp.toString().length <= 10 ? timestamp * 1000 : timestamp); // Multiply if it's in seconds
    const date = new Date(time);

    console.log(time)
    console.log(date)
    return date.toLocaleDateString(); // Format as MM/DD/YYYY
  },
  formatTime(timestamp) {
    const time = parseInt(timestamp.toString().length === 10 ? timestamp * 1000 : timestamp); // Multiply if it's in seconds
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format as HH:MM
  },
  formatMonthDay(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'short', day: 'numeric' }); // Format as Month Name Day
  }
  
}
}



</script>

<style scoped>
/* Adjust spacing and font sizes */
.v-toolbar {
  justify-content: flex-start;
}
.v-btn {
  margin-right: 8px;
}
.v-text-field {
  width: 100%;
}
.v-list-item-title {
  font-size: 14px;
}
.v-list-item-subtitle {
  font-size: 12px;
}
</style>
