<template>
    <v-container>
      <v-card>
        <v-card-title>Create Strategy {{this.userId}}</v-card-title>
        <v-card-text>
          <v-form>
            <!-- Dropdown for Instrument Selection -->
            <v-select
              v-model="selectedInstrument"
              :items="['', ...instruments]"
              label="Select Instrument"
              outlined
              required
            ></v-select>
  
            <!-- Dropdown for Timeframe Selection -->
            <v-select
              v-model="selectedTimeframe"
              :items="['', ...availableTimeframes]"
              label="Select Timeframe"
              outlined
              required
            ></v-select>
  
            <!-- Dropdown for Indicators -->
            <v-select
              v-model="selectedIndicatorCombination"
              :items="['', ...availableIndicatorCombinations]"
              label="Select Indicator Combination"
              outlined
              required
            ></v-select>
  
            <!-- Dropdown for Target and Exit -->
            <v-select
              v-model="targetAndExitType"
              :items="['', ...targetAndExitOptions]"
              label="Select Target and Exit Type"
              outlined
              required
            ></v-select>
  
            <!-- Dropdown for RR (Risk-Reward), shown only if Target and Exit is not Dynamic -->
            <v-select
              v-if="targetAndExitType !== 'Dynamic'"
              v-model="selectedRR"
              :items="['', ...availableRR]"
              label="Select Risk-Reward (RR)"
              outlined
              required
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submitSubscription">Create and Backtest</v-btn>
          <v-btn color="secondary" @click="toggleTable">
            {{ showTable ? "Hide Results" : "Show Results" }}
          </v-btn>
        </v-card-actions>
      </v-card>
  
      <!-- Display Matching Strategies -->
      <v-card v-if="showTable && filteredStrategies.length" class="mt-4">
        <v-card-title>Matching Strategies</v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredStrategies"
          dense
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-container>
  </template>

<script>
import axios from "../axios";

export default {
    props: {
      userId: {
        type: String,
        required: true,
      },

    },
  

  data() {
    return {
      
      selectedStrategy: null, // The selected strategy ID for subscription  
      strategies: [], // Full list of strategies fetched from the API
      selectedInstrument: null, // Selected instrument
      selectedTimeframe: null, // Selected timeframe
      selectedIndicatorCombination: null, // Selected unique indicator combination
      targetAndExitType: null, // Selected target and exit type
      selectedRR: null, // Selected Risk-Reward (RR)
      showTable: false, // Controls visibility of the results table
      instruments: ["NIFTY50", "BANKNIFTY"], // Hardcoded instrument options
      targetAndExitOptions: ["Dynamic", "Percentage"], // Target & Exit options
      headers: [
        { title: "Strategy Name", key: "strategy_name" },
        { title: "Timeframe", key: "TIMEFRAME" },
        { title: "Indicators", key: "INDICATORS" },
        { title: "Entry", key: "ENTRY" },
        { title: "Exit", key: "EXIT" },
        { title: "R:R", key: "RR" },
        { title: "Index", key: "target_exit_index" },
      ],
    };
  },
  computed: {
    // Timeframes available based on selected instrument
    availableTimeframes() {
      if (!this.selectedInstrument) return [];
      const timeframes = this.strategies
        .filter((s) => s.instrument === this.selectedInstrument)
        .map((s) => s.TIMEFRAME)
        .filter(Boolean); // Remove undefined or null values
      return [...new Set(timeframes)];
    },

    // Unique indicator combinations based on selected timeframe
    availableIndicatorCombinations() {
      if (!this.selectedTimeframe) return [];
      const indicators = this.strategies
        .filter(
          (s) =>
            s.instrument === this.selectedInstrument &&
            s.TIMEFRAME === this.selectedTimeframe
        )
        .map((s) => s.INDICATORS);
      return [...new Set(indicators)];
    },

    // Available RR values based on filtered strategies
    availableRR() {
      const filtered = this.strategies.filter((strategy) => {
        const matchesInstrument =
          !this.selectedInstrument || strategy.instrument === this.selectedInstrument;
        const matchesTimeframe =
          !this.selectedTimeframe || strategy.TIMEFRAME === this.selectedTimeframe;
        const matchesIndicatorCombination =
          !this.selectedIndicatorCombination ||
          strategy.INDICATORS === this.selectedIndicatorCombination;
        const matchesTargetAndExit =
          !this.targetAndExitType ||
          strategy.target_exit_index === this.targetAndExitType;

        return (
          matchesInstrument &&
          matchesTimeframe &&
          matchesIndicatorCombination &&
          matchesTargetAndExit
        );
      });

      // Extract unique RR values from filtered strategies
      const rrValues = filtered.map((s) => s.RR).filter(Boolean);
      return [...new Set(rrValues)];
    },

    // Filtered strategies based on all selected criteria
    filteredStrategies() {
      return this.strategies.filter((strategy) => {
        const matchesInstrument =
          !this.selectedInstrument || strategy.instrument === this.selectedInstrument;

        const matchesTimeframe =
          !this.selectedTimeframe || strategy.TIMEFRAME === this.selectedTimeframe;

        const matchesIndicatorCombination =
          !this.selectedIndicatorCombination ||
          strategy.INDICATORS === this.selectedIndicatorCombination;

        const matchesTargetAndExit =
          !this.targetAndExitType ||
          strategy.target_exit_index === this.targetAndExitType;

        const matchesRR =
          !this.selectedRR || strategy.RR === this.selectedRR;

        return (
          matchesInstrument &&
          matchesTimeframe &&
          matchesIndicatorCombination &&
          matchesTargetAndExit &&
          matchesRR
        );
      });
    },
  },
  methods: {
    async fetchStrategies() {
      try {
        const response = await axios.get("/discover/NIFTY50");
        this.strategies = response.data.strategies.map((strategy) => {
          return {
            ...strategy,
            target_exit_index: strategy.EXIT
              ? strategy.EXIT.toLowerCase().startsWith("percentage")
                ? "Percentage"
                : "Dynamic"
              : "Dynamic", // Default to "Dynamic" if target_exit is undefined
          };
        });
      } catch (error) {
        console.error("Error fetching strategies:", error.message || error);
        alert("Failed to load strategies. Please try again later.");
      }
    },
   
    async submitSubscription() {
  try {
    // Ensure there's a strategy available
    if (this.filteredStrategies.length === 0) {
      alert("No strategies available to select.");
      return;
    }

    // Automatically select the first row's strategy ID
    this.selectedStrategy = this.filteredStrategies[0].strategy_id;

    // Post subscription
    await axios.post('/create_subscription', {
      user_id: this.userId, // Logged-in user ID
      strategy_id: this.selectedStrategy, // Strategy ID from the first row
      token: "NA", // Token is always "NA"
      platform_url: "NA", // Platform URL is always "NA"
    });

    // Notify the user of success
    alert('Subscribed successfully!');

    // Emit events to refresh parent components
    this.$emit('strategyAdded');
    this.$emit('refresh');

    // Navigate back to the ActiveStrategies section
    this.$root.selectedView = 'ActiveStrategies';
  } catch (error) {
    console.error('Error subscribing to strategy:', error);
  }
},




    toggleTable() {
      this.showTable = !this.showTable; // Toggle the table visibility
    },
  },
  mounted() {
    this.fetchStrategies();
  },
};
</script>
